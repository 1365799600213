import { CLD_ENV, NODE_ENV } from "../../constants/env";
import { useURLs } from "../../constants/api.urls";
import { useCldURLs } from "~/constants/apiv2.urls";

export const getParentCategoriesHttp = async () => {
  const url = useURLs[NODE_ENV].CATEGORY_PARENT;

  const { data } = await useFetch(url, {
    method: "GET",
    key: "parentCategories",
    headers: {
      client_id: useURLs[NODE_ENV].CLIENT_ID,
      client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
      "Content-Type": "application/json",
    },
    retry: 1,
  });
  return data;
};

export const getCldCategoriesHttp = async () => {
  const url = useCldURLs[CLD_ENV].category.get;

  const { data } = await useFetch(url, {
    method: "GET",
    key: "cldCategories",
    headers: {
      "Content-Type": "application/json",
    },
    retry: 1,
  });
  return data;
};
